import React, { useState} from 'react';
import '../../App.scss';

import image1 from '../Elements/images/carousel/image1.png';
import image2 from '../Elements/images/carousel/image2.png';
import image3 from '../Elements/images/carousel/image3.png';
import image4 from '../Elements/images/carousel/image4.png';
import image5 from '../Elements/images/carousel/image5.png';
import image6 from '../Elements/images/carousel/image6.png';
import fleche from '../Elements/images/carousel/fleche.png';


function Carousel() {

  // Les élements du carousel
  const dataArray = [
    {
      index: 0,
      image: image2,
      alt: 'taureaux galopants',
      titre: 'salut de gardians',
      text: 'Entrez dans l’univers de la Manade Bec. Tout au long d’une aventure de 2h30, vivez la Camargue comme les gardians.',
      durée: '1h30-2h00',
      prix: 'prix sur demande',
    },
    {
      index: 1,
      image: image1,
      alt: 'groupe de huit gardians sur leurs chevaux',
      titre: 'arrambler les taureaux',
      text: 'Entrez dans l’univers de la Manade Bec. Tout au long d’une aventure de 2h30, vivez la Camargue comme les gardians.',
      durée: '1h30-2h00',
      prix: 'prix sur demande',
    },
    {
      index: 2,
      image: image3,
      alt: 'deux gardians sur leurs montures dans la camargue. Atmosphère brumeuse. Lumière jaune dorée.',
      titre: 'tri de taureaux',
      text: 'Entrez dans l’univers de la Manade Bec. Tout au long d’une aventure de 2h30, vivez la Camargue comme les gardians.',
      durée: '1h30-2h00',
      prix: 'prix sur demande',
    },
    {
      index: 3,
      image: image3,
      alt: 'deux gardians sur leurs montures dans la camargue. Atmosphère brumeuse. Lumière jaune dorée.',
      titre: 'bandidos',
      text: 'Entrez dans l’univers de la Manade Bec. Tout au long d’une aventure de 2h30, vivez la Camargue comme les gardians.',
      durée: '1h30-2h00',
      prix: 'prix sur demande',
    },
    {
      index: 4,
      image: image5,
      alt: 'deux gardians sur leurs montures dans la camargue. Atmosphère brumeuse. Lumière jaune dorée.',
      titre: 'ferrades',
      text: 'Entrez dans l’univers de la Manade Bec. Tout au long d’une aventure de 2h30, vivez la Camargue comme les gardians.',
      durée: '1h30-2h00',
      prix: 'prix sur demande',
    },
    {
      index: 5,
      image: image4,
      alt: 'deux gardians sur leurs montures dans la camargue. Atmosphère brumeuse. Lumière jaune dorée.',
      titre: 'passage des taureaux dans le marais',
      text: 'Entrez dans l’univers de la Manade Bec. Tout au long d’une aventure de 2h30, vivez la Camargue comme les gardians.',
      durée: '1h30-2h00',
      prix: 'prix sur demande',
    },
  ];

  //L'index de l'image active  
  const [activeIndex, setActiveIndex] = useState(0);

  // Fonction pour changer l'image active
  const changeActiveImage = ()=>{
    setActiveIndex((prevIndex) => (prevIndex + 1) % dataArray.length); // avancer dans l'index (index précédent +1 /longueur total array(3))
  };

  const activeItem = dataArray[activeIndex];


  return (
    <>
      <div className="grid-col-carousel">

        <div className="carousel-item" id='carousel-text'>
             <h2>{activeItem.titre}</h2>
             <p>{activeItem.text}</p>
             <div className='grid-col-carousel-description'>
              <p className='grid-col-carousel-description-item'>{activeItem.durée}</p>
              <p className='grid-col-carousel-description-item' style={{marginLeft:0}}>{activeItem.prix}</p>
             </div>
        </div>

        <div className="carousel-item">
                <img
                  src= {activeItem.image}
                  alt={activeItem.alt}
                />
        <div className='carousel-buttons'>
              <button id='btn-fleche' aria-label="Flèche" onClick={changeActiveImage}><img id='fleche' src={fleche} alt='icône de flèche'/></button>
        </div>
        </div>
    </div>
    </>
   
  );
}

export default Carousel;
