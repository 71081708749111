import React from 'react'
import '../../App.scss'
import logofooter from '../Elements/icons/homepage/logo_hp.png';
import avion from '../Elements/icons/footer/icon-avion.png';
import voiture from '../Elements/icons/footer/icon-voiture.png';
import bateau from '../Elements/icons/footer/icon-bateau.png';
import train from '../Elements/icons/footer/icon-train.png';

function Footer() {
    const footer = [
        {
          img: voiture,
          locomotion: 'En voiture',
          voies: 'Autoroutes',
          numéro: 'A55 & A7',
          alt: "icône de voiture",
        },
        {
          img: avion,
          locomotion: 'En avion',
          voies: 'Aéroport International',
          numéro: 'Montpellier & Marseille',
          alt: "icône d'avion",
        },
        {
          img: train,
          locomotion: 'En train',
          voies: 'Gare Arles, Miramas, Nîmes',
          numéro: 'Montpellier & Marseille',
          alt: "icône de train",
        },
        {
          img: bateau,
          locomotion: 'En bâteau',
          voies: 'Port des Saintes-Maries-de-la-Mer',
          alt: "icône de bâteau",
        },
      ];

  return (
<div className='grid-footer'>

      <div className='grid-footer-logo'>< img src={logofooter} alt='logo de la manade bec' /></div>
      <div className='grid-footer-adress'>
        <p>AU HAMEAU DE PIN FOURCAT - ROUTE DU BAC DU SAUVAGE 13460 LES SAINTES-MARIES-DE-LA-MER</p>
        <p>GPS : Latitude : 43.505577 / Longitude : 4.353437</p>
      </div>

        {footer.map((item) => (
          <>
                  <div className='grid-footer-item1'>
                    <img key={item.id} src={item.img} alt={item.alt} />
                    <p key={item.id}>{item.locomotion}</p>
                    <p div key={item.id}>{item.voies}</p>
                    <p key={item.id}>{item.numéro}</p>
                  </div>
          </>
        ))}
</div>
  )
}

export default Footer