import React from 'react';
import Typewriter from 'typewriter-effect';
import '../../App.scss';
import Form from '../Components/ContactUs.js';
import Menu from '../Components/MenuExpansif';
import SVGGardian from '../Components/SVGGardian';
import Video from '../Elements/video/video-evenement.mp4';
import familles from '../Elements/images/evenements/famille.jpg';
import familles1 from '../Elements/images/evenements/famille1.jpg';
import entreprises from '../Elements/images/evenements/entreprises.jpg';
import entreprises1 from '../Elements/images/evenements/entreprises1.jpg';
import clubs from '../Elements/images/evenements/clubs.jpg';
import clubs1 from '../Elements/images/evenements/clubs1.jpg';
import salle from '../Elements/images/evenements/salle.jpg';
import laupio1 from '../Elements/images/evenements/laupio.jpg';
import laupio2 from '../Elements/images/evenements/laupio2.jpg';
import Footer from '../Components/Footer.js';
import FooterFixed from '../Components/FooterFixed.js';
import nb from '../Elements/icons/chambres/nb.png';

function Evenements() {
  const catArr = [
    {
      num: '01',
      image: familles,
      alt:'table dressée pour un événement dans la grande salle',
      imageHover: familles1,
      title: 'Famille & amis',
      p:'baptêmes, anniversaires, mariages, réunions de familles'
    },
    {
      num: '02',
      image: entreprises,
      alt:'brunch dans la laupio',
      imageHover: entreprises1,
      title: 'Entreprises',
      p:'séminaires, réunions, after-works'
    },
    {
      num: '03',
      image: clubs,
      alt:'salle de réunion dans la grande salle',
      imageHover: clubs1,
      title: 'Clubs, associations & sorties scolaires',
    },
  ];

  return (
    <>
      <div className='film'>
        <h1 id='titre-video'>
            <Typewriter
              options={{
              strings:["Offrez à votre événement l'écrin parfait", 'pour une expérience mémorable'],
              autoStart: true,
              loop: true,
              }}
            />
          </h1>
          <div className='tiret-hp'></div>
          <div className='background-video'>
            <video className='desktop' autoPlay loop muted>
              <source src={Video} type="video/mp4" />
            </video>
            <video className='mobile' autoPlay loop muted>
              <source src={Video} type="video/mp4" />
            </video>
          </div>
      </div> 


      <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
            <h2 style={{marginTop:0}}>
              <Typewriter 
                options={{
                strings:['Mariage', 'Baptême','Anniversaire','Séminaire'],
                autoStart: true,
                loop: true,
                }}
              />
            </h2>
            <h3>Créons ensemble vos plus beaux souvenirs</h3>
         
          <div style={{marginTop:'7%'}}>
            <Menu catArr={catArr}/>
          </div>
          <p>Le domaine de la Pinède vous ouvre les portes de ses 150 hectares. Entre la majestueuse salle équipée, l'élégante laupio et les vastes espaces vert, chaque élément s'unit pour créer le cadre idéal de votre évènement.</p>
        </div>
      </div>

      {/* LA GRANDE SALLE*/}
      <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
          <h2 style={{marginTop:0}}>Découvrez nos divers</h2>
          <h3>espaces de réception du domaine</h3>
          
           {/* GRILLE DE 4 */}
          <div style={{marginTop:'10%'}} className='grid4'>
            <div className='grid4-description'>
              <h3>La Grande Salle</h3>
              <p>Joyau d'accueil et de célébration : imposante & chaleureuse à la fois, la Grande Salle, avec son sublime bar, offre un refuge élégant pour une multitude d'événements : des moments intimes aux grandes festivités.</p>
              <div className='grid4-pills-item'>
                <img src={nb} alt="icône de personnes"/>
                <p> capacité d'accueil : 160 chaises assises</p>
              </div>
            </div>
            <img src={clubs} className='grid4-img' alt="photographie de l'espace reunion"/>
            <img src={salle} className='grid4-img' alt="photographie du dressage de tables dans la grande salle"/>
            <img src={familles} className='grid4-img' alt="photographie dune table dans la laupio"/>
          </div>
        </div>
      </div>

      {/* LA LAUPIO*/}
      <div className='section' id='motif'>
        <div style={{position:'relative'}}>
           {/* GRILLE DE 4 */}
          <div style={{marginTop:'10%'}} className='grid4'>
            <div className='grid4-description'>
              <h3>La Laupio</h3>
              <p>Sous cette tonnelle, se déroulent les festivités de la manade BEC. La Laupio demeure le lieu par excellence pour des apéritifs & réunions en extérieur sous un cadre enchanteur.</p>
              <div className='grid4-pills-item'>
                <img src={nb} alt="icône de personnes"/>
                <p> capacité d'accueil : 250 personnes</p>
              </div>
            </div>
            <img src={laupio1} className='grid4-img' alt=" autre espace convivial sous la laupio"/>
            <img src={laupio2} className='grid4-img' alt="espace convivial sous la laupio"/>
            <img src={entreprises} className='grid4-img' alt="zoom sur le contenu d'un brunch sous la laupio"/>
          </div>

        </div>
      </div>
          
       {/* TICKET DE CONTACT */}
       <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
            <h2 style={{marginTop:0}}>Vous souhaitez organisez votre évènement au Domaine ?</h2>
            <h3 style={{color:'white'}}>contactez-nous pour en savoir plus</h3>
          
          <div style={{marginTop:'3%'}} className='formulaire'>
            <Form/>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <div className='section' style={{backgroundColor:'#81B795', paddingTop:0}}>
        <div style={{position:'relative'}}>
          <Footer />
          <FooterFixed />
        </div>
      </div>

  </>
  );
}

export default Evenements;
