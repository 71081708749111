import React, { useRef } from 'react';
import '../../App.scss';
import Video from '../Elements/video/hp-desktop.mp4';
import VideoMobile from '../Elements/video/hp-mobile.mp4';
import SVG from '../Components/SVGRotation.js';
import Typewriter from 'typewriter-effect';
import photointro from '../Elements/images/homepage/drone.jpg';
import Menu from '../Components/MenuExpansif.js';
import Map from '../Components/Map.js';
import SVGGardian from '../Components/SVGGardian.js';
import avion from '../Elements/icons/footer/icon-avion.png';
import voiture from '../Elements/icons/footer/icon-voiture.png';
import bateau from '../Elements/icons/footer/icon-bateau.png';
import train from '../Elements/icons/footer/icon-train.png';
import uno from'../Elements/images/homepage/menu-villa.jpg';
import uno1 from'../Elements/images/homepage/menu-villa1.jpg';
import deux from'../Elements/images/homepage/menu-ext.jpg';
import deux1 from'../Elements/images/homepage/menu-ext1.jpg';
import tres from '../Elements/images/homepage/image2.jpg';
import tres1 from '../Elements/images/homepage/image2a.jpg';
import Footer from '../Components/Footer.js';
import FooterFixed from '../Components/FooterFixed.js';


function Intro() {
  const contentRef = useRef(null);
  const contact=['lat. 43.452240 · long. 4.428840 · '];
  const catArr = [
    {
      num: '01',
      image: deux,
      alt:"vue extérieure de la laupio lors d'un événement",
      imageHover:deux1,
      title: 'Un évènement à célébrer',
      p:'le domaine de la pinède vous propose une salle de réception pour tous types d’évènements privés ou professionnels : mariages, anniversaires, baptêmes, réunions et séminaires. Pour les beaux jours, la Laupio vous offre son cadre idyllique pour des apéritifs ou réunions ensoleillés.'
    },
    {
      num: '02',
      image: uno,
      alt:"vue extérieure de la villa Jonquille",
      imageHover: uno1,
      title: 'Séjourner au domaine',
      p:'Pour prolonger l’aventure davantage, le domaine de la pinède vous propose ses gîtes et chambres. vivez au coeur d’une véritable manade et partagez notre quotidien en vous offrant un refuge chaleureux dans une de nos trois villas & chambres, où l\'écho du vent dans les pins accompagnera chacun de vos rêves. '
    },
    {
      num: '03',
      image: tres,
      imageHover:tres1,
      alt:"groupe de huit gardians sur leurs chevaux avec M.BEC",
      title: 'Découvrir la manade',
      p:'Entre les murs ancestraux de La manade bec, emblème de l\'authenticité camarguaise, les gardians perpétuent des traditions séculaires, façonnant la relation unique qui les lie à leurs chevaux et taureaux. visite de la manade en remorque, conduite du troupeau avec les gardians, tri de taureaux, bandido, ferrades : les gardians vous emmènent à l’épicentre d’un héritage culturel indompté.'
    },
  ];
  const footer = [
    {
      img: voiture,
      locomotion: 'En voiture',
      voies: 'Autoroutes',
      numéro: 'A55 & A7',
    },
    {
      img: avion,
      locomotion: 'En avion',
      voies: 'Aéroport International',
      numéro: 'Montpellier & Marseille',
    },
    {
      img: train,
      locomotion: 'En train',
      voies: 'Gare Arles, Miramas, Nîmes',
      numéro: 'Montpellier & Marseille',
    },
    {
      img: bateau,
      locomotion: 'En bâteau',
      voies: 'Port des Saintes-Maries-de-la-Mer',
    },
  ];
 
  return (
    <>
     <div className='film'>
     <SVG/>
      <div className='tiret-hp'></div>
      <div className='background-video'>
        <video className='desktop' autoPlay loop muted>
          <source src={Video} type="video/mp4" />
        </video>
        <video className='mobile' autoPlay loop muted>
          <source src={VideoMobile} type="video/mp4" />
        </video>
      </div>
    </div> 

    <div className='section' id='motif'>
      <div style={{position:'relative'}}>
          <SVGGardian />
          <h1 style={{marginTop:0}}> au coeur de la camargue exactement</h1>
          <img src={photointro} className='pleinpot' alt="Vue extérieure panoramique du domaine" />
          <p>Terre de passion et de tradition, sauvage et secrète, la camargue est un territoire sacré et préservé où la nature est souveraine...</p> 
      </div>
    </div>

    <div className='section' id='motif'>
    <SVGGardian />
      <p className='TexteNarratif'>
          Imaginez votre 
          <span style={{color:'#81B795'}} > 
            <Typewriter 
                options={{
                strings:['évènement', 'mariage', 'anniversaire', 'baptême', 'réunion'],
                autoStart: true,
                loop: true,
                }}
            />
          </span> 
      </p>
      <p className='TexteNarratif'>au milieu de cette immensité sauvage & préservée. Où chaque moment devient une 
        <span style={{color:'#81B795'}}> aventure inoubliable.</span>
      </p> 
    </div>

    <div className='section' id='motif'>
      <SVGGardian />
      <p className='TexteNarratif'>Laissez-nous vous guider à travers cette <span style={{color:'#81B795'}}> expérience unique</span> où la nature elle-même devient le <span style={{color:'#81B795'}}>cadre majestueux</span> de vos plus grands moments.</p> 
    </div>

{/* MENU EXPANSIF */}
    <div className='section' id='motif'>
      <div style={{position:'relative'}}>
        <SVGGardian />
        <h2 style={{marginTop:0}}>votre histoire commence ici</h2>
        <Menu catArr={catArr}/>
        <p>Le domaine de la pinède dévoile ses multiples facettes, célébrant la diversité des plaisirs et des émotions.</p>
      </div>
    </div>

{/* CARTE */}
    <div className='section' id='motif'>
    <div style={{position:'relative'}}>
      <SVGGardian />
      <h2 style={{marginTop:0}}>immersion au coeur de la nature</h2>
      <div id='map'>
        <Map />
      </div>
    </div>
    </div>

    {/* FOOTER */}
    <div className='section' style={{backgroundColor:'#81B795', paddingTop:0}}>
        <div style={{position:'relative'}}>
          <Footer />
          <FooterFixed />
        </div>
      </div>
  </>
  );
}

export default Intro;
