import React from "react";
import { NavLink } from "react-router-dom";
import logonav from '../Elements/icons/logo/logo-nav.png';
import Burger from './NavbarBurger';

function Navbar() {


return (
 <div className="navbar" >

    <div className="navbar-left">
        <NavLink to={`/`}> 
        <img src={logonav} alt='logo du domaine de la pinède' /> 
        </NavLink>
    </div>

    <div className="navbar-right">
        <Burger />
    </div>
    
</div>  
)}
  
export default Navbar