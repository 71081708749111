import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import {useParams} from 'react-router-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

function ContactUsGite() {
  const { id } = useParams(); // Récupérer l'ID depuis l'URL
  const form = useRef();

  const [sujet, setSujet] = useState('');
  const [message, setMessage] = useState('');


  // Obtenir sujet du formulaire si user a clic sur le ticket
  useEffect(() => {
    if (id && id !== ':id') {
      // id existe et n'est pas égal à ':id'
      setSujet(`Demande pour ${id}`); // Utiliser l'ID dans le sujet
      // Faire défiler jusqu'au formulaire lorsque l'ID est présent dans l'URL
      form.current.scrollIntoView();
    } else if (id) {
      setSujet(`Demande pour [entrez votre sujet]`); // Utiliser l'ID dans le sujet
    }
  }, [id]);


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm('service_bmio3wm', 'template_rn55h75',form.current, {
        publicKey: 'X5ffqtJN8gM2JfL7W',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setMessage('  La Manade BEC a bien reçu votre demande et vous recontactera très prochainement !👋')
        },
        (error) => {
          console.log('FAILED...', error.text);
          setMessage('Oops ça n\'a pas fonctionné.. Veuillez recommencer ultérieurement');
        },
      );
  };

  //Pop-up de confirmation à l'envoi du formulaire
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);


  return (
    <>

      <form className='formGite' ref={form} onSubmit={sendEmail}>
        <div></div>
        <div className='grid-containerz'>
          <div className='grid-itemz'>
              <label>Sujet</label>
              <input type="text" name="sujet" value={sujet} onChange={(e) => setSujet(e.target.value)}/>
            </div>
            <div className='grid-itemz'>
              <label>Nom</label>
              <input type="text" name="name" placeholder='Votre nom/prénom' />
            </div>
            <div className='grid-itemz'>
              <label>Email</label>
              <input type="email" name="email" placeholder='Votre email' />
            </div>
            
            <div className='grid-itemz'>
            <label>Message</label>
              <textarea name="message" placeholder='Entrez votre message' />
              <input id='btn-form' type="submit" value="Envoyer" onClick={onOpenModal}/>
              {message}
              <Modal className='modal' open={open} onClose={onCloseModal} center>
                   <p style={{color:'black'}}>La Manade BEC a bien reçu votre demande et vous recontactera très prochainement !👋 </p>
              </Modal>

          </div>
        </div>
      </form>



      <form className='formMobileGite' ref={form} onSubmit={sendEmail}>
        
        <div></div>


        <div className='grid-containerz'>

          <div className='grid-itemz'>
              <label>Sujet</label>
              <input type="text" name="sujet" value={sujet} onChange={(e) => setSujet(e.target.value)}/>
            </div>
          <div className='grid-itemz'>
            <label>Nom</label>
            <input type="text" name="name" placeholder='Votre nom/prénom' />
          </div>
          <div className='grid-itemz'>
            <label>Email</label>
            <input type="email" name="email" placeholder='Votre email' />
          </div>
            
          <div className='grid-itemz'>
            <label>Message</label>
            <textarea name="message" placeholder='Entrez votre message' />
            <input id='btn-form' type="submit" value="Envoyer" onClick={onOpenModal}/>
            {message}
            <Modal className='modal' open={open} onClose={onCloseModal} center>
                   <p style={{color:'black'}}>La Manade BEC a bien reçu votre demande et vous recontactera très prochainement !👋 </p>
              </Modal>
          </div>
        </div>
      </form>

    </>
    
  );
};
export default ContactUsGite;