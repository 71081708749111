import '../../App.scss';
import React from 'react';

import Form from '../Components/ContactUs.js';
import croquis from '../Elements/images/croquis-vert.png';
import avion from '../Elements/icons/footer/icon-avion-vert.png';
import voiture from '../Elements/icons/footer/icon-voiture-vert.png';
import bateau from '../Elements/icons/footer/icon-bateau-vert.png';
import train from '../Elements/icons/footer/icon-train-vert.png';
import SVGGardian from '../Components/SVGGardian.js';
import tel from '../Elements/icons/footer/phone.png'



function Contact() {

  const call = ()=>{
    window.location.href = 'tel://' + '0603900620';
  }
  const footer = [
    {
      img: voiture,
      locomotion: 'En voiture',
      voies: ['En provenance de Marseille','En provenance de Montpellier','En provenance d\'Arles'],
      numéro: ['A55 / A7 / A54 jusqu’à Arles D570 - Dir. Les Saintes-Maries-de-la-Mer','D66 / D62 Via Aigues-Mortes','D570, puis D38C et D58 - Dir. Aigues-Mortes'],
    },
    {
      img: avion,
      locomotion: 'En avion',
      voies: ['Aéroport International Montpellier Méditerranée','Aéroport International Marseille / Provence'],
      numéro: ['(47 kms) - www.montpellier.aeroport.fr ', 'Aéroport International Marseille / Provence', '(134 kms) - www.marseille.aeroport.fr'],
    },
    {
      img: train,
      locomotion: 'En train',
      voies: ['Gare Arles (T.E.R.) - ', ' Gare Miramas (T.E.R.) - ', ' Gare Nîmes (T.G.V.) - ', ' Gare Montpellier St-Roch (T.G.V.) - ', ' Gare Marseille St-Charles (T.G.V.) - ', ' Gare Arles, Miramas, Nîmes - '],
      numéro: 'www.voyages-sncf.com',
    },
    {
      img: bateau,
      locomotion: 'En bâteau',
      voies: 'Port des Saintes-Maries-de-la-Mer',
      numéro: 'www.portsaintemarie66.com',
    },
  ];

  return (
    <>

        <div className='section' id='motif'>
          <div style={{position:'relative'}}>
              <SVGGardian />
              <h2>Domaine de la Pinède</h2>
              <h3 style={{color:'#81B795'}}>se rendre sur place</h3>
          </div>
        </div>

              <div className='contact'>
                <p>AU HAMEAU DE PIN FOURCAT - ROUTE DU BAC DU SAUVAGE 13460 LES SAINTES-MARIES-DE-LA-MER</p>
                <p>GPS : Latitude : 43.505577 / Longitude : 4.353437</p>
                <div className='contact-item'><img src={tel} alt='icon de téléphone'/><p onClick={call}>06 03 90 06 20</p></div>
              </div>    

         <div className='transports'>
                {footer.map((item, index) => (
                  <div className='transport-items' key={index}>
                    <div className='transport-header'>
                      <img src={item.img} alt={item.locomotion} />
                      <p style={{color:'#81B795'}}>{item.locomotion}</p>
                    </div>
                    <div className='transport-text'>
                      {/* Mapping through voies and numéro */}
                          {(Array.isArray(item.voies) && Array.isArray(item.numéro)) 
                            ?
                            item.voies.map((voie, idx) => (
                              <div key={idx}>
                                <p style={{margin:0}}>{voie}</p>
                                <p>{item.numéro[idx]}</p>
                              </div>
                            )) 
                            :
                            <div>
                              <p style={{margin:0}}>{item.voies}</p>
                              <p>{item.numéro}</p>
                            </div>
                          }
                    </div>
                  </div>
                ))}
              </div>
        {/* CROQUIS */}
        <div className='contact-croquis-parent'>
            <img src={croquis}  id='contact-croquis' alt='croquis au trait vert de la manade bec'/>
        </div>  
         
        {/* TICKET DE CONTACT */}
        <div className='section' id='motif'>
          <div style={{position:'relative'}}>
            <div className='formulaire'>
              <Form/>
            </div>
          </div>
        </div>
    </>
  );
}

export default Contact;
