import './App.scss';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

// Les pages
import Navbar from './assets/Components/Navbar';
import Intro from './assets/Views/Intro.js';
import Manade from './assets/Views/manade';
import Gites from './assets/Views/gites';
import Evenements from './assets/Views/evenements.js';
import Contact from './assets/Views/contact';
import Ticket from './assets/Components/Ticket';


function App() {

  const [isPageScrolled, setIsPageScrolled] = useState(false);

    useEffect(() => {
    const handleScroll = () => {
    //Vérifier si la page est entièrement défilée
    const scrollThreshold = document.body.offsetHeight - window.innerHeight - 800; // Supprimer footer à partir de la section 'immersion au coeur de la nature
    const isScrolledToBottom = window.scrollY >= scrollThreshold;
    setIsPageScrolled(isScrolledToBottom);
  };

    //Ajouter un écouteur d'événement pour le défilement de la fenêtre
  window.addEventListener('scroll', handleScroll);
   //Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className="App">
      <Router>
        <Navbar/>
        <Routes>
      {/* For every URL we can render a separate component */}
          <Route path="/" element={<Intro />} />
          <Route path="/:id/manade" element={<Manade />} />
          <Route path="/:id/gites" element={<Gites />} />
          <Route path="/:id/evenements" element={<Evenements />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        {!isPageScrolled && <Ticket className="ticket" />}
        
      </Router>
    </div>
  );
}

export default App;
