import React from 'react';
import '../../App.scss';
import { MapContainer, TileLayer, Marker  } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import icon from '../Elements/icons/map/marker-domaine.png'



function Map() {
 
    const markers = [
        {
          geocode : [43.45214,4.42913],
          popUp : 'Domaine de la Pinède'
        }
      ]
      const customIcon = new Icon ({
        iconUrl: icon,
        iconSize: [38,38],
      })
      const redirectToGoogleMaps = () => {
        const address = 'Manade Bec, Pin Fourcat - 13460 Les-Saintes-Maries-de-la-Mer';
        const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(address)}`;
        window.location.href = googleMapsUrl;
      }
    
      return (
        <MapContainer center={[43.45214,4.42913]} zoom={15} >
        <TileLayer
          attribution='&copy;<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
         />
         {markers.map(marker =>(
          <button onClick={redirectToGoogleMaps} aria-label="Google Maps Tag">
            <Marker position={marker.geocode} icon={customIcon} eventHandlers={{
              click: () => redirectToGoogleMaps('Manade Bec, Pin Fourcat - 13460 Les-Saintes-Maries-de-la-Mer')
            }}></Marker>
          </button>
         ))
        }
      </MapContainer>
      );
    
}
export default Map;

