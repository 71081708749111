import '../../App.scss';
import React from 'react';
import SvgLines from 'react-mt-svg-lines';    // ES6+


function SVGPath() {

  return (
   <>
    <SvgLines animate={ 3000 } duration={ 3000 } timing="ease-in-out" >
        <svg id="svg" viewBox="0 0 801.1 768">
          <path fill="none" stroke="#e6e6e6" stroke-miterlimit="10" stroke-width="6px" class="cls-1" d="M620,723.7c-12.6,11.1-32.2,8.4-41.3-5.7-41.4-63.8-144.7-219.7-168.1-225.1-35-8.1-162.2,48.6-267-.9C39,442.7,4.1,397.6,5.5,389.6c6.3-34.7,120.7,38.5,250.1-2.2,68.4-21.5,63.9-44.1,61.1-77.1-2.4-28.1-18.9-61.1,2.9-75.6,29.3-19.5,100.3,42.2,135.2,27.6,34-14.2,98.9-80,98.9-246.8,0-6,7.3-9.1,11.7-5,21.9,20.5,67.6,72.3,74.2,151.4,8.1,96.8-59.6,210.9-71.3,234.1-7.1,14.2,116,124.2,172.3,173.5,14.4,12.6,14.4,35,0,47.7l-120.8,106.5Z"/>
          <path fill="none" stroke="#e6e6e6" stroke-miterlimit="10" stroke-width="6px" class="cls-1" d="M413.3,493s26.9-137.2,155-93.9"/>
          <path fill="none" stroke="#e6e6e6" stroke-miterlimit="10" stroke-width="6px" class="cls-1" d="M7.7,385.2s233.4,129.8,419.6-32.7C636.8,169.6,561.7,16.2,558.9,9.3"/>
          <line fill="none" stroke="#e6e6e6" stroke-miterlimit="10" stroke-width="6px" class="cls-1" x1="317.5" y1="234.7" x2="423.9" y2="353"/>
        </svg>
    </SvgLines>
   </>
  );
}

export default SVGPath;