import React, { useState } from 'react';
import { NavLink} from "react-router-dom";
import '../../App.scss';
import logonav from '../Elements/icons/homepage/hp.png'
import LogoHP from '../Elements/icons/homepage/logo_hp.png';
import croix from '../Elements/icons/homepage/close.png';
import burger from '../Elements/icons/homepage/hamburger.png';


function NavbarBurger() {

    const [menuOpen, setMenuOpen] = useState(false);
    const [iconMenu, seticonMenu]=useState(burger);

    const toggleMenu = ()=>{
        setMenuOpen(!menuOpen);
        changeIcon();
    }
    const changeIcon = ()=>{
        seticonMenu(prevIcon => prevIcon === burger ? croix : burger);

    }


return (
    <>
     <div  className="navbar-right" style={{position:'relative'}}> 

        <div className="navbar-menu" onClick={toggleMenu}>
            <img id='croix' src={iconMenu} alt="icône hamburger" />
        </div>


       <div className={`navbar-full ${menuOpen ? 'open' : ''}`} >
     
            <div className="navbar-croix" onClick={toggleMenu}>
                <img id='croix' src={iconMenu} alt="icône de croix"/>
            </div>

           <NavLink to={`/`} onClick={toggleMenu}>
           <img src={LogoHP} id='logo-navbar' alt="logo du Domaine de la Pinède" />
           </NavLink>
           <div className="navbar-full-items">
               <NavLink to={`/:id/evenements`} onClick={toggleMenu}>
                   <p id='evenement' >Les évènements</p>
               </NavLink>
               <NavLink to={`/:id/gites`} onClick={toggleMenu}>
                   <p id='gites' >Les gîtes</p>
               </NavLink>
               <NavLink to={`/:id/manade`} onClick={toggleMenu}>
                   <p id='manade' >La manade</p>
               </NavLink>
               <NavLink to={`/contact`} onClick={toggleMenu}>
                   <p id='contact' >Nous contacter</p>
               </NavLink>
           </div>

           <div className="navbar-full-items2">
               <img src={logonav} alt="logo du Domaine de la Pinède" />
           </div>

       </div>
   </div>
    </>
   
)}
  
export default NavbarBurger
