import '../../App.scss';
import React from 'react';
import { Link, BrowserRouter as Router } from "react-router-dom";

// Les pictos
import croix from '../Elements/icons/ticket/croix_camarguaise_logo.png';
import gites from '../Elements/icons/ticket/icon_gite.png';
import manade from '../Elements/icons/ticket/icon_cheval.png';
import salle from '../Elements/icons/ticket/icon_salle.png';
import mariage from '../Elements/icons/ticket/icon_mariage.png';
import baptême from '../Elements/icons/ticket/icon_bapteme.png';
import anniversaire from '../Elements/icons/ticket/icon_anniv.png';

function Ticket() {

  const ticketLiens= [
    {
      reservation: 'la salle de réception',
      lien:'/evenements',
      img: salle,
    },
    {
      reservation: 'mariage',
      lien:'/evenements',
      img: mariage,
    },
    {
      reservation: 'baptême',
      lien:'/evenements',
      img: baptême,
    },
    {
      reservation: 'anniversaire',
      lien:'/evenements',
      img: anniversaire,
    },
    {
      reservation: 'réunions',
      lien:'/evenements',
      img: baptême,
    },
    {
      reservation: 'les gîtes',
      lien:'/gites',
      img: gites,
    },
    {
      reservation: 'chambres',
      lien:'/gites',
      img: gites,
    },
    {
      reservation: 'la manade',
      lien:'/manade',
      img: manade,
    },
  ]

return (
  <div className="ticket" style={{backgroundSize: 'cover'}}>
      <div className='ticket-logo'>
        <img src={croix} alt="Croix Camrguaise dorée" />
        <p>je me renseigne</p>
      </div>
      <div className='liens'>
        <div className='liens-items'>
          {/* Après link renvoie vers formulaire mail et met en objet le nom du lien  */}
          {ticketLiens.map((lien, index) => (
                <Link key={index} to={`/${lien.reservation}${lien.lien}` }> {/* Utilisation de la balise Link */}
                  <div className='liens-item'>
                    <img src={lien.img} alt={lien.reservation} />
                    <p id='p-ticket'>{lien.reservation}</p> {/* récupère lien réservation */}
                  </div>
                </Link>
            ))}
        </div>
      </div>
    </div>  
     
  );
}

export default Ticket;
