import '../../App.scss';
import React, { useState, useEffect } from 'react';
import gardian from '../Elements/icons/svg/pattern-gardian.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css"; // animate css animations


function SVGGardian() {

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    // Utilisez un délai pour simuler le chargement
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 300); // 1000ms pour correspondre à la durée de transition définie dans le CSS
    return () => clearTimeout(timeout);
  }, []); // Effectue cette action uniquement après le premier rendu


  return (
   <div className='section-absolute' style={{zIndex:'1', position:'absolute', opacity:'.2', pointerEvents:'none'}}>
    <AnimationOnScroll animateIn="animate__fadeIn" className='animate__delay-1s' >  
      <div id='svg-gardian-top'>
        <img src={gardian} alt='illustration de motif gardian'/>
      </div>
      <div id='svg-gardian-bottom'>
        <img src={gardian} alt='illustration de motif gardian'/>
      </div>
    </AnimationOnScroll>
   </div>
  );
}

export default SVGGardian;

    