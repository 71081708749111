import '../../App.scss';
import Logo from '../Elements/icons/homepage/hp.png';

const SVGRotation = () => {

  const contact=['l a t. 4 3.4 5 2 2 4 0 · l o n g . 4.4 2 8 8 4 0 · '];

  return(
    <>
    <div className='Conteneur'>
          <a href='#contact'>
            <svg
            width="100%" 
            height="100%"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            className='rotate-svg'
            >
              <path
                id="circlePath"
                d="M 10, 50 a 40,40 0 1,1 80,0 40,40 0 1,1 -80,0"
                fill='transparent'
              />
              <text
              fill='white'>
                <textPath href="#circlePath">{new Array(1).fill(contact).join('')}</textPath>
              </text>
            </svg>
            <div className='logo-roue'>
            <img src={Logo} alt='le D du Domaine de la Pinède' />
            </div>
          </a>
      
  </div>
    </>
  )
  
  };

export default SVGRotation;
