import '../../App.scss';
import React from 'react';
import VerticalCarousel from '../Components/VerticalCarousel';
import Typewriter from 'typewriter-effect';
import ManadeVideo from '../Elements/video/videomanade.mp4';
import manade1 from '../Elements/images/manade/manade1.jpg';
import manade2 from '../Elements/images/manade/manade2.jpg';
import manade3 from '../Elements/images/manade/entree.jpg';
import manade4 from '../Elements/images/manade/manade4.jpg';
import manade5 from '../Elements/images/manade/manade5.jpg'
import Trident from '../Components/SVGTrident';
import SVGGardian from '../Components/SVGGardian.js';
import cursorBEC from '../Elements/icons/logo/cursorbec.png';
import cocarde from '../Elements/images/manade/cocarde.png';
import logobec from '../Elements/logos/logo-bec.png';
import Footer from '../Components/Footer.js';
import FooterFixed from '../Components/FooterFixed.js';
import Form from '../Components/ContactUs.js';



function Manade() {

  return (
    <>
    <div className='film'>
          <h1 id='titre-video'>
              <Typewriter
                options={{
                strings:['Bienvenue à la Manade BEC'],
                autoStart: true,
                loop: true,
                }}
              />
            </h1>
            <div className='tiret-hp'></div>
            <div className='background-video'>
              <video className='desktop' autoPlay loop muted>
                <source src={ManadeVideo} type="video/mp4" />
              </video>
              <video className='mobile' autoPlay loop muted>
              <source src={ManadeVideo} type="video/mp4" />
            </video>
            </div>
        </div> 


       {/* GRILLE 1 */}
       <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
          <div className='grid-manade'>
            <img className='grid-manade1' src={manade1} alt="entrée de la manade par le petit pont"/>
            <img className='grid-manade2' src={manade2}  alt="taureaux fixant la caméra" />
            <p className='grid-manade-text'>
              symbole vivant <span style={{color:'#81B795'}}>de l'âme indomptée </span>
              d'une 
              <span style={{color:'#81B795'}}> terre de légendes </span>
            </p>
            <img className='grid-manade-svg' src={cursorBEC}  alt="Le B de la manade BEC" />
          </div>
        </div>
      </div>

      {/* GRILLE 2 */}
       <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
          <div className='grid-manade'>
            <img className='grid-manade1' src={manade3}  alt="mur en pierre de l'entrée du domaine de la Pinède avec le nom gravé dedans" />
            <img className='grid-manade2' src={manade4}  alt="vue panoramique du domaine avec la laupio au loin"/>
            <p className='grid-manade-text'>
            dont les racines <span style={{color:'#81B795'}}>plongent pleinement </span>
            dans l'histoire 
            <span style={{color:'#81B795'}}> camarguaise </span>
            </p>
            <div id='trident'>
              <Trident />
            </div>
          </div>
        </div>
      </div>

      {/* GRILLE 3 */}
      <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
          <div className='grid-manade'>
            <div id='logobec'>
              <img  src={logobec}  alt="logo de la Manade BEC"/>
            </div>
            <img className='grid-manade2' src={manade5}  alt="groupe de chevaux blancs en vue aérienne"/>
            <p className='grid-manade-text'>
            La Manade "BEC" accueille les visiteurs en quête de <span style={{color:'#81B795'}}> dépaysement.</span>
            </p>
            <img className='grid-manade-svg' src={cocarde}  alt="illustration de la cocarde BEC" />
            
          </div>
        </div>
      </div>

    <div className='section'>
      <div style={{position:'relative'}}>
          <h2 style={{marginTop:0}}>découverte de la manade</h2>
          <h3 style={{color:'#81B795'}}> en charrette </h3>
          <VerticalCarousel />
      </div>
    </div>

    {/* TICKET DE CONTACT */}
    <div className='section' id='motif'>
        <div style={{position:'relative'}}>
          <SVGGardian />
            <h2 style={{marginTop:0}}>Vous souhaitez découvrir la manade</h2>
            <h3 style={{color:'#81B795'}}> réservez votre visite </h3>
          <div style={{marginTop:'3%'}} className='formulaire'>
            <Form/>
          </div>
        </div>
      </div>

    {/* FOOTER */}
    <div className='section' style={{backgroundColor:'#81B795', paddingTop:0}}>
        <div style={{position:'relative'}}>
          <Footer />
          <FooterFixed />
        </div>
      </div>

    </>
  );
}

export default Manade;
