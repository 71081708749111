import React, { useState} from 'react'
import '../../App.scss';

function MenuExpansif({catArr}) {
    const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <>
      <div className="grid-container">
        {catArr.map((item,index) => (
        <>
            <div className='grid-item' 
                style={{ backgroundImage: hoveredIndex === index ?`url(${item.imageHover})` : `url(${item.image})`, alt:`${item.alt}`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
            >
                <div key={item.id} className="grid-id">{item.num}</div>
                <div key={item.id} className="grid-title"><h3>{item.title}</h3></div>
                <div key={item.id} className="grid-text"><p style={{width:'fit-content'}} >{item.p}</p></div>
           </div>
        </>
        ))}
      </div>
    </>
  )

}

export default MenuExpansif

